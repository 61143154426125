/* autoprefixer grid: autoplace */

:root {
    --fontFamilyBase: Edmondsans, sans-serif;
    --fontFamilySecond: "Comic-Sans", sans-serif;

    --invalidColor: red;
    --primary-default: #1B365D;
    --primary-hover: #294875;
    --primary-alternative-default: #FFFFFF;
    --primary-outline-hover: #BBC3CF;

    --text-default: #253746;
    --text-white: #FFFFFF;
    --text-light-grey: #9A9A9A;
    --text-red: #B00C00;
    --text-green: #35A900;

    --inputs-default: #CFD6DD;
    --inputs-text-placeholder: #ADADAD;
    --inputs-text: #253746;

    --border-light: #DBDBDB;

    --icons-default: #1B365D;
    --icons-white: #FFFFFF;
    --icons-light-blue: #AEC2DD;

    --correct-incorrect-incorrect: #DA291C;
    --correct-incorrect-incorrect-darker: #B00C00;
    --correct-incorrect-correct: #6DD400;
    --correct-incorrect-correct-dark: #35A900;
    --correct-incorrect-other-answer: #6388BF;
    --correct-incorrect-other-answer-dark: #294875;
    --correct-incorrect-showing-correct-answer: #E4DDC1;
    --correct-incorrect-showing-correct-answer-dark: #CFC5A1;

    --bg-default: #294875;
    --bg-white: #FFFFFF;
    --bg-cream: #F3F1EF;
    --bg-dark-cream: #E4DDC1;
    --bg-light-blue: #AEC2DD;
    --bg-mid-blue: #6388BF;
    --bg-dark-blue: #1B365D;
    --bg-tint-blue: #E4EDF9;
    --bg-gold: #AF9A52;
    --bg-void: #757575;
}

html, body {
    font-family: var(--fontFamilyBase);
    min-height: 100%;
    height: 100%;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    font-family: var(--fontFamilyBase);
    -webkit-tap-highlight-color: transparent;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--fontFamilyBase);
    font-weight: 900;
}

h1 {
    font-size: 40px;
    line-height: 48px;
}

h2 {
    font-size: 26px;
    line-height: 36px;
}

h3 {
    font-size: 20px;
    line-height: 28px;
}

h4 {
    font-size: 18px;
    line-height: 25px;
}

h5 {
    font-family: var(--fontFamilyBase);
    font-size: 18px;
    line-height: 20px;
}

h6 {
    font-family: var(--fontFamilyBase);
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

input,
button,
select {
    outline: none;
    font-family: var(--fontFamilyBase);
}

button {
    cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
    font-size: 16px !important;
}

.MuiBackdrop-root.MuiModal-backdrop {
    backdrop-filter: blur(10px);
    background: rgba(27, 54, 93, 0.7);
}

.overflow-popover .MuiBackdrop-root {
    opacity: 0 !important;
}

/*Fix for popover arrow if needed*/
.overflow-popover > .MuiPaper-root {
    overflow: visible;
}
