@font-face {
    font-family: 'Edmondsans';
    src: url('Edmondsans-Medium.otf') format('opentype');
    font-weight: 410;
    font-style: normal;
}

@font-face {
    font-family: 'Edmondsans';
    src: url('Edmondsans-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Edmondsans';
    src: url('Edmondsans-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}
